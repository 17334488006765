export default {
    addressData(addressData, placeResultData) {
        // changing data to the format currently in the api
        var address = {
            raw: addressData.name,
            route: addressData.route,
            street_number: addressData.street_number,
            formatted: placeResultData.formatted_address,
            latitude: addressData.latitude,
            longitude: addressData.longitude,
            locality: {
                postal_code: addressData.postal_code,
                name: addressData.locality,
                state: {
                    name: placeResultData.address_components.find(
                        (elm) => elm.short_name == addressData.administrative_area_level_2
                    ).long_name,
                    code:
                        placeResultData.address_components.find(
                            (elm) => elm.long_name == addressData.country
                        ).short_name +
                        "-" +
                        addressData.administrative_area_level_2,
                    country: {
                        name: addressData.country,
                        code: placeResultData.address_components.find(
                            (elm) => elm.long_name == addressData.country
                        ).short_name,
                    },
                },
            },
        };
        return address
    },

}