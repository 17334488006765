<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="400"
    v-model="dialog"
    @click:outside="modalAction"
  >
    <v-container class="pt-1 background rounded-lg">
      <v-row class="ma-0"
        ><v-col cols="12" class="pa-0"
          ><v-icon
            class="mt-2 float-right"
            color="secondary"
            @click="modalAction"
            >mdi-close</v-icon
          ></v-col
        ></v-row
      >
      <v-row
        ><v-col
          class="pt-0 text-center primary--text font-weight-bold"
          cols="12 "
          v-html="text"
        ></v-col
      ></v-row>
      <v-row justify="center" class="mb-1">
        <v-col cols="12" md="7" class="d-flex justify-center">
          <v-btn
            large
            color="red"
            class="white--text rounded-lg"
            @click="action"
            >{{ deleteText }}</v-btn
          >
        </v-col>
        <v-col cols="12" md="5" class="d-flex justify-center"
          ><v-btn
            @click="modalAction"
            class="rounded-lg"
            large
            color="primary"
            outlined
            >{{ $t("draft.cancel") }}</v-btn
          ></v-col
        >
      </v-row>
    </v-container>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import AuthService from "@/services/AuthService.js";
export default {
  props: ["dialog"],
  data() {
    return {
      loadingButton: false,
      mapper: {
        candidate: {
          text: this.$i18n.t("delete.candidate_popup"),
          delete: this.$i18n.t("delete.candidate_title"),
          action: function () {
            AuthService.deleteUser(this.info.id).then((response) => {
              if (response.status === 204) {
                this.logout();
                this.$router.push({ name: "login" });
              }
            });
          },
        },
        pharmacy: {
          text: this.$i18n.t("delete.candidate_popup"),
          delete: this.$i18n.t("delete.candidate_title"),
          action: function () {
            AuthService.deleteUser(this.info.id).then((response) => {
              if (response.status === 202) {
                this.logout();
                this.$router.push({ name: "login" });
              }
            });
          },
        },
        pharmacygroup: {
          text: this.$i18n.t("delete.candidate_popup"),
          delete: this.$i18n.t("delete.candidate_title"),
          action: function () {
            AuthService.deleteUser(this.info.id).then((response) => {
              if (response.status === 202) {
                this.logout();
                this.$router.push({ name: "login" });
              }
            });
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({ type: "user/type" }),
    ...mapGetters({ info: "user/info" }),
    userType() {
      return this.type || "undefined";
    },
    text() {
      return this.mapper[this.userType].text;
    },
    deleteText() {
      return this.mapper[this.userType].delete;
    },
    action() {
      return this.mapper[this.userType].action.bind(this);
    },
  },
  methods: {
    ...mapActions({ logout: "user/logout" }),
    modalAction() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>
<style scoped>
.background {
  background-color: white;
  background-repeat: no-repeat;
  background-position: bottom;
}
.theme--light.v-divider {
  border-color: var(--v-secondary-base) !important;
}
</style>
