<template>
  <v-container fluid>
    <layout :back="true" />
    <div class="mx-4">
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="6" md="4" class="pa-0">
            <h3 class="font-weight-black">{{ $t("profile.edit") }}</h3>
          </v-col>
        </v-row>
        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0 mt-2">
            <span class="text-subtitle-1 pl-2">{{
              $t("field.pharmacy_logo")
            }}</span>
            <v-row class="pa-0 ma-0" justify-sm="center">
              <v-col cols="7" sm="8" md="4" class="pa-0">
                <v-btn
                  color="secondary"
                  class="mt-2"
                  block
                  outlined
                  large
                  rounded
                  @click="addlogo"
                  style="text-transform: none"
                >
                  {{ $t("register.pharmacy_logo") }}
                  <v-icon right dark> mdi-paperclip </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-file-input
              small-chips
              id="logo"
              :label="$t('field.logo')"
              truncate-length="15"
              v-model="pharmacyGroup.logo"
              class="pa-0"
              :class="pharmacyGroup.logo != null ? '' : 'd-none'"
              accept="image/*"
            ></v-file-input>
          </v-col>
        </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("group_fields.group_name") }}</v-subheader
            >
            <v-text-field
              v-model="pharmacyGroup.name"
              :label="$t('group_fields.group_name')"
              hide-details="auto"
              outlined
              validate-on-blur
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.representative_name") }}</v-subheader
            >
            <v-text-field
              name="input-10-1"
              :label="$t('field.representative_name')"
              v-model="pharmacyGroup.representative"
              hide-details="auto"
              outlined
              validate-on-blur
              :rules="required"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.email") }}</v-subheader
            >
            <v-text-field
              name="input-10-1"
              :label="$t('field.email')"
              v-model="pharmacyGroup.email"
              hide-details="auto"
              outlined
              validate-on-blur
              :rules="emailRules"
            >
            </v-text-field>
          </v-col>
        </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.cif") }}</v-subheader
            >
            <v-text-field
              :label="$t('field.cif_format')"
              v-model="pharmacyGroup.cif"
              :rules="cif"
              validate-on-blur
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- <v-row class="pa-0 ma-0" justify-sm="center">
        <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
          <v-subheader class="pa-0 ma-0 text-caption primary--text"
            ><span class="red--text text-subtitle-2">*</span
            >{{ $t("field.email") }}</v-subheader
          >
          <v-text-field
            :label="$t('field.email')"
            hide-details="auto"
            outlined
            v-model="pharmacyGroup.email"
            :rules="required"
          ></v-text-field>
        </v-col>
      </v-row> -->
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.phone_number") }}</v-subheader
            >
            <vue-tel-input
              :requiredPass="requiredDefault"
              :phoneNumber="pharmacyGroup.phone_number"
              @updatePhoneNumber="updatePhoneNumber"
              @updateFormValid="updateFormValid"
              @updateCountry="updateCountryTelephone"
            />
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0 ma-0">
            <v-subheader class="pa-0 ma-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.fiscal_name") }}</v-subheader
            >
            <v-text-field
              v-model="pharmacyGroup.fiscal_name"
              :label="$t('field.fiscal_name')"
              hide-details="auto"
              outlined
              validate-on-blur
              :rules="required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" justify-sm="center">
          <v-col cols="12" sm="8" md="4" class="pa-0">
            <v-subheader class="pa-0 text-caption primary--text"
              ><span class="red--text text-subtitle-2">*</span
              >{{ $t("field.fiscal_address") }}</v-subheader
            >
            <vuetify-google-autocomplete
              ref="address"
              :id="'map3'"
              placeholder=""
              :rules="addressValidator"
              classname="form-control"
              :label="$t('field.address_placeholder')"
              :value="
                pharmacyGroup.billing_address
                  ? pharmacyGroup.billing_address.formatted
                  : null
              "
              outlined
              hide-details
              v-on:placechanged="getBillingAddressData"
              country="es"
            >
            </vuetify-google-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mx-2 my-8" justify="center">
          <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 mr-0 text-center">
            <div>
              <v-btn
                color="transparent"
                large
                rounded
                class="customButton primary--text"
                @click="$router.go(-1)"
              >
                {{ $t("offer.cancel") }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" sm="4" md="2" class="pa-0 mt-2 text-center">
            <div>
              <v-btn
                color="secondary"
                large
                rounded
                :disabled="!formValid || !phoneValid"
                @click="updatePharmacyGroup"
              >
                {{ $t("operation.save") }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <delete-user></delete-user>
  </v-container>
</template>
<script>
import Layout from "@/components/layouts/Profile.vue";
import VueTelInput from "@/components/shared/VueTelInput.vue";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import AddressDataHelper from "@/helpers/AddressData.js";
import DeleteUser from "../../components/shared/DeleteUser.vue";
export default {
  components: {
    Layout,
    VueTelInput,
    DeleteUser,
  },
  data() {
    return {
      pharmacyGroup: {},
      required: [(v) => !!v || this.$i18n.t("validation.required")],
      countryCode: "",
      formValid: false,
      phoneValid: true,
      requiredDefault: true,
      emailRules: [
        (v) => /.+@.+/.test(v) || this.$i18n.t("validation.email_format"),
      ],
      mobile_number: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /^\+?[0-9]{1,3}[\s-]?[0-9]{8,12}$/.test(v) ||
          this.$i18n.t("validation.phone_number"),
      ],
      cif: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          /(^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$)|(^\d{8}[a-zA-Z]{1}$)/.test(v) ||
          this.$i18n.t("validation.cif"),
      ],
      web_page: [
        (v) =>
          !v ||
          /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/.test(
            v
          ) ||
          this.$i18n.t("validation.web_page"),
      ],
      addressValidator: [
        (v) => !!v || this.$i18n.t("validation.required"),
        (v) =>
          (v &&
            this.pharmacyGroup.billing_address.latitude &&
            this.pharmacyGroup.billing_address.latitude != 0) ||
          this.$i18n.t("validation.address"),
      ],
      code: [
        {
          name: "España (+34)",
          icon: "",
          code: "+34 ",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ _pharmacyGroup: "pharmacy/pharmacyGroup" }),
    ...mapGetters({ groupId: "user/groupId" }),
  },
  methods: {
    ...mapActions({ getPharmacyGroup: "pharmacy/getPharmacyGroup" }),
    ...mapActions({ _updatePharmacyGroup: "pharmacy/updatePharmacyGroup" }),
    ...mapActions({ getUser: "user/getUser" }),

    getBillingAddressData(addressData, placeResultData) {
      this.pharmacyGroup.billing_address = AddressDataHelper.addressData(
        addressData,
        placeResultData
      );
    },
    async updatePharmacyGroup() {
      this.formatOwnerTelephone();
      // try {
      let status = await this._updatePharmacyGroup({
        id: this.groupId,
        name: this.pharmacyGroup.name,
        representative: this.pharmacyGroup.representative,
        email: this.pharmacyGroup.email,
        cif: this.pharmacyGroup.cif,
        phone_number: this.pharmacyGroup.phone_number,
        billing_address: this.pharmacyGroup.billing_address,
        fiscal_name: this.pharmacyGroup.fiscal_name,
      });
      if (status === 200) {
        this.$notify({
          title: this.$i18n.t("notification.profile_update"),
          text: this.$i18n.t("notification.profile_update"),
          type: "success",
        });
        this.$router.push({ name: "profile" });
      }
      // } catch {
      //   this.$notify({
      //     title: this.$i18n.t("notification.profile_update"),
      //     text: this.$i18n.t("notification.profile_update"),
      //     type: "success",
      //   });
      // }
    },
    addlogo() {
      document.getElementById("logo").click();
    },
    updatePhoneNumber(phone) {
      this.pharmacyGroup.phone_number = phone;
    },
    updateCountryTelephone(country) {
      this.countryCode = "+" + country;
    },
    updateFormValid(valid) {
      this.phoneValid = valid;
    },
    formatOwnerTelephone() {
      if (this.pharmacyGroup.phone_number) {
        let phone = this.pharmacyGroup.phone_number;
        if (phone.startsWith("+")) {
          let tel = this.pharmacyGroup.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacyGroup.phone_number = country + " " + phone2;
        } else {
          let tel = this.countryCode + this.pharmacyGroup.phone_number;
          tel = tel.split(" ");
          let country = tel[0];
          tel.shift();
          let phone = tel.join();
          let phone2 = phone.replaceAll(",", "");
          this.pharmacyGroup.phone_number = country + " " + phone2;
        }
      }
    },
  },
  async created() {
    await this.getPharmacyGroup(this.groupId);
    this.pharmacyGroup = _.cloneDeep(this._pharmacyGroup);
  },
};
</script>
