
<template>
  <div>
    <v-row class="pa-0 ma-0" justify-sm="center">
      <v-col cols="12" sm="8" md="4" class="pa-0 mt-5">
        <h4 class="font-weight-black mb-3">
          {{ $t("delete.candidate_title") }}
        </h4>
        <p style="font-size: 0.9rem">
          {{ $t("delete.candidate_text") }}
        </p>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0" justify-sm="center">
      <v-col class="pa-0 mb-6" cols="12" sm="8" md="4">
        <span
          style="cursor: pointer; text-decoration: underline"
          @click="showConfirm = true"
        >
          {{ $t("delete.candidate_title") }}
        </span>
      </v-col>
    </v-row>
    <delete-user-confirm :dialog.sync="showConfirm"></delete-user-confirm>
  </div>
</template>
<script>
import DeleteUserConfirm from "@/components/modals/DeleteUserConfirm.vue";
export default {
  components: {
    DeleteUserConfirm,
  },
  data() {
    return {
      showConfirm: false,
    };
  },
  methods: {},
};
</script>

